<template>
    <div class="my-box">
        <div>
            <span>系统消息:</span>
        </div>
        <div class="content-box">
            <van-list
                v-model="loading"
                :finished="finished"
                offset="100"
                finished-text="数据加载完毕"
                @load="onLoad"
            >
                <div class="content-item" v-for="item in list" :key="item.id">
                    <div class="left">
                        <span>{{ item.createTime }}</span>
                    </div>
                    <div class="right">
                        <span>{{ item.data }}</span>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { List } from "vant";
import { selectListByUserId } from "@/api/my";
export default {
    components: {
        [List.name]: List,
    },
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            page: 0,
            limit: 10
        };
    },
    async created() {
        document.title = "我的消息";
        this.$store.commit("updateBarText", "我的消息");
    },
    methods: {
        onLoad() {
            this.page = this.page + 1
            this.getList()
        },
        getList() {
            selectListByUserId({
                page: this.page,
                certId: this.bookValue,
                limit: this.limit,
            }).then(res => {
                const { data } = res.data;
                if (data.data.length == 0) {
                    this.finished = true;
                } else {
                    this.list = [...this.list, ...data.data]
                    if (data.current_page == data.last_page) {
                        this.finished = true;
                    } else {
                        this.loading = true;
                    }
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.my-box {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    padding-top: 70px;
    .content-box {
        .content-item {
            height: 100px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #d6d6d6;
            >div{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .left {
                flex: 1;
                color: #4E4E4E;
                font-size: 13px;
            }
            .right {
                flex: 1;
                color: #4E4E4E;
                font-size: 14px;
            }
        }
    }
}
</style>